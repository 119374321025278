import _ from 'lodash';
import {Component, HostListener} from '@angular/core';
import fontawesome from '@fortawesome/fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/';
import {dic} from './dictionary';
import {GeneralService} from './services/general.service';
import {TranslationService} from './services/translation.service';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {RouteService} from './services/route.service';
import {LookAndFeelService} from './services/lookAndFeelService';
import {CommonModule} from '@angular/common';
import {LoadingAnimationComponent} from './components/loading-animation/loading-animation.component';
import {HeaderComponent} from './components/header/header.component';
import {TranslateModule} from '@ngx-translate/core';
import {BtnComponent} from './components/uiComponents/btn/btn.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [CommonModule, LoadingAnimationComponent, HeaderComponent, RouterOutlet, TranslateModule, BtnComponent]
})
export class AppComponent{
	dic = dic;
	_ = _;

	timeoutIdle;
	isTimeout = false;
	currentAppState;

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e) {
		this.resetTimer();
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.resetTimer();
	}

	constructor(public gs: GeneralService,
				public lfs: LookAndFeelService,
				public router: Router,
				public activatedRoute: ActivatedRoute,
				public rs: RouteService,
				private translationService: TranslationService) {
		this.currentAppState = location.pathname.split('/')[1];

		// Init fontawesome
		fontawesome.library.add(faTrashAlt);

		this.translationService.initLanguage();

		this.resetTimer();

		// set user hash in http headers
		const accessEncSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.setAccessEnc();
				setTimeout(() => {
					this.lfs.getLfSettings();
				});
				accessEncSubscription.unsubscribe();
			}
		});
	}

	resetTimer() {
		if (this.isTimeout) {
			return;
		}
		clearTimeout(this.timeoutIdle);
		this.timeoutIdle = setTimeout(() => {
			this.isTimeout = true;
		}, 15 * 60 * 1000); // 15 minutes
	}

	reloadPage() {
		window.location.reload();
	}

	setAccessEnc() {
		const currentRoutePath = this.activatedRoute.snapshot.firstChild?.routeConfig?.path;
		if (currentRoutePath) {
			const userHash = this.router.url.split(currentRoutePath + '/')[1];

			if (userHash) {
				const defaultHeaders = {
					[this.dic.HEADERS.xAccessEnc]: userHash
				};

				const localStorageFingerprint = this.getFingerPrint(currentRoutePath);
				if (localStorageFingerprint) {
					try {
						defaultHeaders[this.dic.HEADERS.x2FaFingerprint] = JSON.parse(localStorageFingerprint);
					} catch (ex) {
						console.log(ex);
					}
				}

				this.rs.setDefaultHeaders(defaultHeaders);
			}
		}
	}

	private getFingerPrint = (routePath) => {
		switch (routePath) {
			case dic.CONSTANTS.appstates.quarantined:
			case dic.CONSTANTS.appstates.acp:
			case dic.CONSTANTS.appstates.review:
			case dic.CONSTANTS.appstates.outbound:
			case dic.CONSTANTS.appstates.sender:
				return localStorage[this.dic.CONSTANTS.quarantinedFp];

			case dic.CONSTANTS.appstates.recipient:
			case dic.CONSTANTS.appstates.archive:
				return localStorage[this.dic.CONSTANTS.localStorageFp];
		}
	}
}
