import {enableProdMode, importProvidersFrom} from '@angular/core';

import {environment} from './environments/environment';
import {TooltipModule, TooltipOptions} from 'ng2-tooltip-directive';
import {Restangular, RestangularModule} from 'ngx-restangular';
import {ATTACHMENT_URL, BE_URL, Restangular_Attachments} from './app/constants';
import {HttpClient, provideHttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {RouteService} from './app/services/route.service';
import {DownloadFilesService} from './app/services/download-files.service';
import {TranslationService} from './app/services/translation.service';
import {GeneralService} from './app/services/general.service';
import {LookAndFeelService} from './app/services/lookAndFeelService';
import {NotificationService} from './app/services/notification.service';
import {ToastrModule} from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {provideRouter} from '@angular/router';
import {appRoutes} from './app/routes';

if (environment.production) {
	enableProdMode();
}

const defaultTooltipOptions: TooltipOptions = {
	'hide-delay': 0
};

export function RestangularAttachmentsFactory(restangular: Restangular) {
	return restangular.withConfig((RestangularConfigurer) => {
		RestangularConfigurer.setBaseUrl(ATTACHMENT_URL);
	});
}


export function RestangularConfigFactory(RestangularProvider) {
	RestangularProvider.setBaseUrl(BE_URL);
	RestangularProvider.setPlainByDefault(true);
	// RestangularProvider.setDefaultHeaders({timeout: 10000});
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
	providers: [
		provideRouter(appRoutes),
		provideHttpClient(),
		LookAndFeelService,
		RouteService,
		DownloadFilesService,
		TranslationService,
		GeneralService,
		LookAndFeelService,
		NotificationService,
		{ provide: Restangular_Attachments, useFactory:  RestangularAttachmentsFactory, deps: [Restangular] },
		importProvidersFrom(RestangularModule.forRoot(RestangularConfigFactory)),
		importProvidersFrom(TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions)),
		importProvidersFrom(ToastrModule.forRoot()),
		importProvidersFrom(TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			defaultLanguage: 'us'
		})),
	],
});
