<div class="app-container animated fadeIn">
	<div class="content-page-container flxClmn-center" [ngClass]="{'full-screen' : gs.isFullScreen, 'is-mobile' : gs.isMobile}">
		<div class="border-effect flxClmn" style="flex: 1 0"
			 [ngStyle]="{'max-width' : !isTimeout && gs.globalWidth || '', 'max-height' : !isTimeout && gs.globalHeight || ''}"
			 [ngClass]="isTimeout ? 'sm' : {
						 'sm': currentAppState === dic.CONSTANTS.appstates.reply,
						 'md' : !_.values(dic.CONSTANTS.appstates).includes(currentAppState)
						  		|| [dic.CONSTANTS.appstates.sender, dic.CONSTANTS.appstates.tracking].includes(currentAppState),
			 			 'lg' : currentAppState === dic.CONSTANTS.appstates.outbound}">
			<div class="content-container flxClmn t-bg-primary" style="flex: 1 0 auto">
				<app-loading-animation [hideCondition]="!gs.isLoadingApp" ></app-loading-animation>

				<ng-container *ngIf="!isTimeout">
					<!--Logo header-->
					<app-header class="logo-container"></app-header>

					<router-outlet></router-outlet>
				</ng-container>

				<div class="flxClmn f-1-0 centralize" *ngIf="isTimeout">
					<img src="assets/images/sessionTimeout.png" style="width: 240px; margin: 0; image-rendering: -webkit-optimize-contrast;">
					<h2 class="t-color-primary" style="font-size: clamp(16px,2.2rem,7vw); font-weight: bold; margin-bottom: 0; ">{{'general.sessionExpired' | translate}}</h2>
					<h3 style="font-size: clamp(14px,1.2rem,5vw); color: var(--gray2);">{{'general.sessionExpiredInactivity' | translate}}</h3>
					<h3 style="font-size: clamp(14px,1.2rem,5vw); color: var(--gray2); font-weight: 400;">{{'general.pleaseRefreshPage' | translate}}</h3>
					<br/>
					<btn style="font-size: 1rem;" (action)="reloadPage();">
						{{ 'general.refresh' | translate }}
					</btn>
				</div>
			</div>
		</div>

		<!--"need help" (recipient page only)-->
		<ng-container *ngIf="!isTimeout && !_.values(dic.CONSTANTS.appstates).includes(currentAppState)">
			<div class="flxClmn vSpace05" style="color: white; z-index: 2; font-size: 0.75rem; margin-top: 15px; text-align: center"
				 *ngIf="!gs.isFullScreen && !gs.isMobile">
				<span style="opacity: 0.8">
					{{ 'recipient.notSureMsg' | translate}}
				</span>
				<span *ngIf="lfs.helpUrl">
					{{ 'recipient.needHelp' | translate}}?
					<a href="{{lfs.helpUrl}}" style="color: white"  target="_blank">
						{{ 'recipient.clickHere' | translate}}
					</a>
				</span>
			</div>
		</ng-container>

	</div>
</div>
