import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
	selector: 'app-loading-animation',
	templateUrl: './loading-animation.component.html',
	styleUrls: ['./loading-animation.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule]
})
export class LoadingAnimationComponent {
	@Input() hideCondition: boolean;
}
