import {Routes} from '@angular/router';
import {dic} from './dictionary';

export const appRoutes: Routes = [
	{path: dic.CONSTANTS.appstates.tracking, loadComponent: () => import('./components/tabs/email-tabs-wrapper/email-tabs-wrapper.component').then(m => m.EmailTabsWrapperComponent), children: [{path: '**', loadComponent: () => import('./components/tabs/email-tabs-wrapper/email-tabs-wrapper.component').then(m => m.EmailTabsWrapperComponent)}]},
	{path: dic.CONSTANTS.appstates.quarantined, loadComponent: () => import('./components/quarantined-email/quarantined-email.component').then(m => m.QuarantinedEmailComponent), children: [{path: '**', loadComponent: () => import('./components/quarantined-email/quarantined-email.component').then(m => m.QuarantinedEmailComponent)}]},
	{path: dic.CONSTANTS.appstates.outbound, loadComponent: () => import('./components/outbound-quarantined/outbound-quarantined.component').then(m => m.OutboundQuarantinedComponent), children: [{path: '**', loadComponent: () => import('./components/outbound-quarantined/outbound-quarantined.component').then(m => m.OutboundQuarantinedComponent)}]},
	{path: dic.CONSTANTS.appstates.acp, loadComponent: () => import('./components/account-takeover-protection/account-takeover-protection.component').then(m => m.AccountTakeoverProtectionComponent), children: [{path: '**', loadComponent: () => import('./components/account-takeover-protection/account-takeover-protection.component').then(m => m.AccountTakeoverProtectionComponent)}]},
	{path: dic.CONSTANTS.appstates.sender, loadComponent: () => import('./components/sender/sender.component').then(m => m.SenderComponent), children: [{path: '**', loadComponent: () => import('./components/sender/sender.component').then(m => m.SenderComponent)}]},
	{path: dic.CONSTANTS.appstates.reply, loadComponent: () => import('./components/secure-reply/secure-reply.component').then(m => m.SecureReplyComponent), children: [{path: '**', loadComponent: () => import('./components/secure-reply/secure-reply.component').then(m => m.SecureReplyComponent)}]},
	{path: dic.CONSTANTS.appstates.archive, loadComponent: () => import('./components/archive/archive.component').then(m => m.ArchiveComponent), children: [{path: '**', loadComponent: () => import('./components/archive/archive.component').then(m => m.ArchiveComponent)}]},
	{path: dic.CONSTANTS.appstates.review, loadComponent: () => import('./components/review/review.component').then(m => m.ReviewComponent), children: [{path: '**', loadComponent: () => import('./components/review/review.component').then(m => m.ReviewComponent)}]},
	{path: '', loadComponent: () => import('./components/recipient/recipient.component').then(m => m.RecipientComponent), children: [{path: '**', loadComponent: () => import('./components/recipient/recipient.component').then(m => m.RecipientComponent)}]},
];

/*const routerOptions: ExtraOptions = {
	// enableTracing: true,
	// initialNavigation: 'disabled',
	onSameUrlNavigation: 'reload',
	useHash: false,
	preloadingStrategy: PreloadAllModules
};*/

//export const RouterModuleProvider = RouterModule.forRoot(appRoutes);
